import React, { useState, useEffect } from 'react';

function ReportList() {
    const [reports, setReports] = useState([]);
    const [reportForm, setReportForm] = useState({
        title: '',
        diagnosis: '',
        price: '',
        appointmentId: '' // Bu alan backend'deki entity yapınıza göre ayarlanmalı
    });
    const [editingReportId, setEditingReportId] = useState(null);

    useEffect(() => {
        fetchReports();
    }, []);

    const fetchReports = async () => {
        const response = await fetch('/api/reports');
        if (response.ok) {
            const data = await response.json();
            setReports(data);
        }
    };

    const handleFormChange = (e) => {
        setReportForm({ ...reportForm, [e.target.name]: e.target.value });
    };

    const handleAddOrUpdateReport = async (e) => {
        e.preventDefault();
        const method = editingReportId ? 'PUT' : 'POST';
        const endpoint = editingReportId ? `/api/reports/${editingReportId}` : '/api/reports';

        const response = await fetch(endpoint, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reportForm)
        });

        if (response.ok) {
            fetchReports();
            setEditingReportId(null);
            setReportForm({ title: '', diagnosis: '', price: '', appointmentId: '' });
        }
    };

    const handleEdit = (report) => {
        setEditingReportId(report.id);
        setReportForm({ ...report });
    };

    const handleDelete = async (reportId) => {
        const response = await fetch(`/api/reports/${reportId}`, { method: 'DELETE' });
        if (response.ok) {
            fetchReports();
        }
    };

    return (
        <div>

        </div>
    );
}

export default ReportList;